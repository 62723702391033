import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import { withRouter } from "../CustomHooks/withRouter";
import Banner from "../util/Banner/Banner";
import UserHeader from "../Header/UserHeader";
import Cookies from "js-cookie";
import customAxios from "../axios";
import { toast } from "react-toastify";
import "./index.css";
import Screening from "../Teleconsultation/Screening/Screening";
import Photos from "../Teleconsultation/Photos/Photos";
import Scans from "../Teleconsultation/Scans/Scans";

function FreelanceCase({ router }) {
  const [loadingPage, setLoadingPage] = useState(true);
  const [expanded, setExpanded] = useState("details-accordion");
  const [user, setUser] = useState(null);
  const [shipping, setShipping] = useState(null);
  const [responseScreening, setResponseScreening] = useState(null);
  const [impresionsAndPhotosResponse, setImpresionsAndPhotosResponse] = useState(null);
  const [scansResponse, setScansResponse] = useState(null);

  let { id } = router.params;

  const handleChange = (e) => {
    setExpanded((prev) => {
      if (prev === e) {
        return "";
      }
      return e;
    });
  };
  const getTp = async () => {
    try {
      let res = await customAxios.get(`freelance/case/${id}`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
      });
      if (res.data.success) {
        setUser(res.data.client);
        setShipping(res.data.shipping);
        setResponseScreening(res.data.info);
        setImpresionsAndPhotosResponse(res.data.photos);
        setScansResponse(res.data.scans);
      }
    } catch (e) {
      console.log(e);
      toast.error("an Error has occured");
    }
    setLoadingPage(false);
  };
  useEffect(() => {
    getTp();
  }, []);

  if (loadingPage) {
    return (
      <div className="loading-container" style={{ marginTop: "10vh" }}>
        <div className="loading"></div>
      </div>
    );
  }
  return (
    <div style={{ marginTop: "10vh" }}>
      <div className="teleConsultContainer">
        <UserHeader
          name={user.full_name + " - " + user.id}
          router={router}
          isFreelanceCase
        />
        <Accordion
          disableGutters
          expanded={expanded == "details-accordion"}
          onChange={() => {
            handleChange("details-accordion");
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="details-accordion-content"
            id="details-accordion-header"
          >
            <Typography
              variant="h6"
              sx={{ color: "#ff2c46", fontWeight: "bold" }}
            >
              Patient Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                width: "100%",
                whiteSpace: "nowrap",
                overflowX: "auto",
                display: "flex",
                gap: "5%",
                flexDirection: "column",
              }}
            >
              {shipping != null ? (
                <div>
                  <Typography
                    sx={{ fontSize: 18, fontWeight: "bold" }}
                    gutterBottom
                  >
                    {user.full_name} - {user.id}
                  </Typography>
                  <Typography sx={{ fontSize: 16 }}>
                    {user.email}
                  </Typography>
                  <Typography
                    sx={{ fontSize: 18, fontWeight: "bold" }}
                    gutterBottom
                  >
                    Address:
                  </Typography>
                  <Typography sx={{ fontSize: 16 }}>
                    {shipping.phone}
                  </Typography>
                  <Typography sx={{ fontSize: 16 }} color="text.secondary">
                    {shipping.address}
                  </Typography>
                  <Typography sx={{ fontSize: 16 }} color="text.secondary">
                    {shipping.address2}
                  </Typography>
                  <Typography sx={{ fontSize: 16 }} color="text.secondary">
                    {shipping.address3}
                  </Typography>
                  <Typography sx={{ fontSize: 16 }} color="text.secondary">
                    {shipping.apartment}
                  </Typography>
                  <Typography sx={{ fontSize: 16 }} color="text.secondary">
                    {shipping.postal_code}
                  </Typography>
                  <Typography sx={{ fontSize: 16 }} color="text.secondary">
                    {shipping.note}
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5, fontSize: 16 }}
                    color="text.secondary"
                  >
                    {shipping.city}, {shipping.country}
                  </Typography>
                </div>
              ) : null}
              <Screening
                data={responseScreening}
              />
              <Photos
                data={impresionsAndPhotosResponse}
              />
              <Scans
                data={scansResponse}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default Banner(withRouter(FreelanceCase));
