import { Suspense } from "react";
import "./App.css";
import SignIn from "./Login";
import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProductionPlans from "./ProductionPlans";
import Plan from "./Plan";
import FreelanceCase from "./FreelanceCase";

function App() {
  let location = useLocation();
  return (
    <>
      <Suspense fallback="Loading…">
        <Routes location={location} key={location.key}>
          <Route path="/" element={<SignIn />} />
          <Route path="/plans" element={<ProductionPlans />} />
          <Route path="/plan/:id" element={<Plan />} />
          <Route path="/freelance-case/:id" element={<FreelanceCase />} />
          <Route path="*" element={<div>404</div>} />
        </Routes>
      </Suspense>
      <ToastContainer />
    </>
  );
}

export default App;
