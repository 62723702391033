import { useState, forwardRef, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Slide from "@mui/material/Slide";
import ImageListThumbnails from "./image-list-thumbnails.component";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const getProceduresWithIPRSheet = (procedures) => {
  return procedures.filter((procedure) => "url" in procedure);
};

// const useStyles = styled(() => ({
//   customButton: {
//     fontWeight: "500 !important",
//     borderRadius: "3px !important",
//     fontSize: "12px !important",
//   },
// }));

const PDFSheetPreview = ({ procedures }) => {
  const [open, setOpen] = useState(false);
  const [pdfUrls, setPdfUrls] = useState([]);
  const [maxStep, setMaxStep] = useState(0);
  const [activeStep, setActiveStep] = useState(0);

  const setUpPdfUrls = () => {
    const filteredProceduresUrls = getProceduresWithIPRSheet(procedures);
    const pdfUrls = filteredProceduresUrls.map((procedure) => {
      if (procedure.previous) {
        return { step: "Previous IPR", url: procedure.url };
      }
      return {
        step: `Step ${procedure.step}`,
        url: process.env.REACT_APP_AWS_URL + procedure.url,
      };
    });
    setMaxStep(pdfUrls.length);
    setPdfUrls(pdfUrls);
  };

  const handleClickOpen = (index) => {
    setOpen(true);
    setActiveStep(index);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    setUpPdfUrls();
  }, []);

  return (
    <>
      <ImageListThumbnails
        pdfUrls={pdfUrls}
        handleClickOpen={handleClickOpen}
      />

      {pdfUrls && (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          fullScreen
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <Box sx={{ height: "98%", width: "100%" }}>
              <Box sx={{ height: "98%", width: "100%" }}>
                <object
                  data={pdfUrls[activeStep]?.url}
                  type="application/pdf"
                  width="100%"
                  height="100%"
                >
                  <p>
                    Alternative text - include a link{" "}
                    <a href="http://africau.edu/images/default/sample.pdf">
                      to the PDF!
                    </a>
                  </p>
                </object>
              </Box>
              <MobileStepper
                variant="text"
                steps={maxStep}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button
                    onClick={handleNext}
                    disabled={activeStep === maxStep - 1}
                  >
                    Next
                    <KeyboardArrowRight />
                  </Button>
                }
                backButton={
                  <Button onClick={handleBack} disabled={activeStep === 0}>
                    <KeyboardArrowLeft />
                    Back
                  </Button>
                }
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default PDFSheetPreview;
