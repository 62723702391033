import React, { useEffect, useState } from "react";
import customAxios from "../axios";
import Cookies from "js-cookie";
import { TbMoodEmpty } from "react-icons/tb";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Button, ButtonGroup, TableHead } from "@mui/material";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { withRouter } from "../CustomHooks/withRouter";
import Banner from "../util/Banner/Banner";

function ProductionPlans({ router }) {
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [plans, setPlans] = useState([]);
  const [isFreelancer, setIsFreelancer] = useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getPlans = async () => {
    try {
      let res = await customAxios.get(`plans_to_produce?page=${page}`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
      });
      setIsFreelancer(res.data.is_freelancer);
      setPlans([...res.data.data.data]);
      setCount(res.data.data.total);
      setMaxPage(res.data.data.last_page);
    } catch (e) {}
  };
  const updatePlans = async (plan_id, action) => {
    try {
      let res = await customAxios.get(`plans/${plan_id}/${action}`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
      });
      if (!res.data.success) {
        toast.error(res.data.reason);
      } else {
        router.navigate(0);
      }
    } catch (e) {
      toast.error("an Error has occured");
    }
  };

  useEffect(() => {
    getPlans();
  }, [page]);

  return (
    <TableContainer
      component={Paper}
      sx={{ maxWidth: "50%", margin: "auto", marginTop: "20vh" }}
    >
      <Table aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            <TableCell align="center">ID</TableCell>
            <TableCell align="center">Name of Patient</TableCell>
            {!isFreelancer && <TableCell align="center">Actions</TableCell>}
          </TableRow>
        </TableHead>
        {
          !isFreelancer ?
          <TableBody>
            {plans.map((row) => (
              <TableRow
                key={row.id}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  router.navigate(`/plan/${row.id}`);
                }}
              >
                <TableCell component="td" scope="row" align="center">
                  {row.user_id}
                </TableCell>
                <TableCell align="center">{row.user.full_name}</TableCell>
                <TableCell align="center">
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={() => {
                        updatePlans(row.id, "start");
                      }}
                      sx={{ color: "#ff2c46" }}
                      disabled={row.oem_production_start_time ? true : false}
                    >
                      Started
                    </Button>
                    <Button
                      onClick={() => {
                        updatePlans(row.id, "end");
                      }}
                      sx={{ color: "#ff2c46" }}
                      disabled={
                        !row.oem_production_start_time ||
                        row.oem_production_end_time
                      }
                    >
                      Completed
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody> :
          <TableBody>
            {plans.map((row) => (
              <TableRow
                key={row.user.id}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  router.navigate(`/freelance-case/${row.id}`);
                }}
              >
                <TableCell component="td" scope="row" align="center">{row.user.id}</TableCell>
                <TableCell align="center">{row.user.full_name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        }
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={8}
              count={count}
              page={page - 1 < 0 ? page : page - 1}
              rowsPerPage={20}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              ActionsComponent={() => {
                return (
                  <TablePaginationActions
                    maxPage={maxPage}
                    page={page}
                    count={count}
                    onPageChange={handleChangePage}
                  />
                );
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export default Banner(withRouter(ProductionPlans));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, onPageChange, maxPage } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, maxPage);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= maxPage}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= maxPage}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
