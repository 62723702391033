import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import Logo from "../../img/logoWhite.png";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import "./Banner.css";

export default function Banner(Component) {
  function ComponentWithBanner() {
    let navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="fixed" sx={{ backgroundColor: "#ff2c46" }}>
            <Toolbar className="banner-wrapper">
              <Box
                component="img"
                sx={{
                  width: "10%",
                }}
                alt="Basma"
                src={Logo}
                onClick={() => {
                  navigate("/plans");
                }}
              />
              <Avatar
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              />
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <RadioGroup name="radio-buttons-group">
                  <MenuItem onClick={()=> {
                    Cookies.remove('access_token');
                    navigate('/')
                  }}>
                    Logout
                  </MenuItem>
                </RadioGroup>
              </Menu>
            </Toolbar>
          </AppBar>
        </Box>
        <Component />
      </>
    );
  }
  return ComponentWithBanner;
}
