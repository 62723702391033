import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardMedia,
} from "@mui/material";
import customAxios from "../../axios";
import { useParams } from "react-router-dom";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import "./Photos.css";
import Cookies from "js-cookie";

export default function Photos({ data = null }) {
  let { id } = useParams();
  const [impresionsAndPhotosResponse, setImpresionsAndPhotosResponse] = useState(data);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [imagesArray, setImagesArray] = useState([]);
  const [loading, setLoading] = useState(false);

  const downloadPhotos = () => {
    setLoading(true);
    customAxios.get(`/freelance/users/${impresionsAndPhotosResponse?.step3?.user_id}/download-screening-photos/${impresionsAndPhotosResponse?.step3?.id}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("access_token"),
      },
    }).then((result) => {
      var win = window.open(
        `${process.env.REACT_APP_BASE_URL_V2}/storage${result.data.data}`,
        "_blank"
      );
      win.focus();
    }).catch((err) => {
      console.log('err', err);
    }).finally(f => {
      setLoading(false);
    });
  }

  function handleShowLargeImage(e) {
    setIsOpen(!isOpen);
    setPhotoIndex(
      imagesArray.indexOf(findArrayElementById(imagesArray, e.currentTarget.id))
    );
  }
  function findArrayElementById(array, id) {
    return array.find((element) => {
      return element.id === id;
    });
  }

  useEffect(() => {
    if (data) {
      return;
    }
    customAxios.get(`users/${id}/impressions&photos/teleconsult-photos`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("access_token"),
      },
    }
    ).then(function (response) {
      const responseImpressionsAndPhotos = response.data.data;
      responseImpressionsAndPhotos && setImpresionsAndPhotosResponse(responseImpressionsAndPhotos);
    });
  }, []);

  useEffect(() => {
    if (impresionsAndPhotosResponse) {
      setImagesArray([
        {
          id: "step3a",
          image:
            impresionsAndPhotosResponse.step3 &&
              impresionsAndPhotosResponse.step3.center_bite_url
              ? impresionsAndPhotosResponse.step3.center_bite_url
              : "",
        },
        {
          id: "step3b",
          image:
            impresionsAndPhotosResponse.step3 &&
              impresionsAndPhotosResponse.step3.left_bite_url
              ? impresionsAndPhotosResponse.step3.left_bite_url
              : "",
        },
        {
          id: "step3c",
          image:
            impresionsAndPhotosResponse.step3 &&
              impresionsAndPhotosResponse.step3.right_bite_url
              ? impresionsAndPhotosResponse.step3.right_bite_url
              : "",
        },

        {
          id: "step3d",
          image:
            impresionsAndPhotosResponse.step3 &&
              impresionsAndPhotosResponse.step3.upper_teeth_url
              ? impresionsAndPhotosResponse.step3.upper_teeth_url
              : "",
        },
        {
          id: "step3e",
          image:
            impresionsAndPhotosResponse.step3 &&
              impresionsAndPhotosResponse.step3.lower_teeth_url
              ? impresionsAndPhotosResponse.step3.lower_teeth_url
              : "",
        },
        {
          id: "step3f",
          image:
            impresionsAndPhotosResponse.step3 &&
              impresionsAndPhotosResponse.step3.front_facing_no_smile_url
              ? impresionsAndPhotosResponse.step3.front_facing_no_smile_url
              : "",
        },
        {
          id: "step3g",
          image:
            impresionsAndPhotosResponse.step3 &&
              impresionsAndPhotosResponse.step3.front_facing_big_smile_url
              ? impresionsAndPhotosResponse.step3.front_facing_big_smile_url
              : "",
        },
        {
          id: "step3h",
          image:
            impresionsAndPhotosResponse.step3 &&
              impresionsAndPhotosResponse.step3.right_side_profile_no_smile_url
              ? impresionsAndPhotosResponse.step3
                .right_side_profile_no_smile_url
              : "",
        },
        {
          id: "step4a",
          image:
            impresionsAndPhotosResponse.step4 &&
              impresionsAndPhotosResponse.step4.x_ray_url
              ? impresionsAndPhotosResponse.step4.x_ray_url
              : "",
        },
        {
          id: "step4b",
          image:
            impresionsAndPhotosResponse.step4 &&
              impresionsAndPhotosResponse.step4.gum_score_url
              ? impresionsAndPhotosResponse.step4.gum_score_url
              : "",
        },
      ]);
    }
  }, [impresionsAndPhotosResponse])

  return (
    <div className="photosContainer">
      <h1 className="photosTitle">PHOTOS</h1>

      <Card>
        <div className="photos-download-btn">
          <Button disabled={loading} onClick={downloadPhotos}>Download Photos</Button>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            rowGap: 30,
            margin: 40,
            padding: '2rem'
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginInlineStart: 20,
              alignItems: "center",
            }}
          >
            <div
              id="step3a"
              style={{
                marginBottom: 10,
              }}
              onClick={(e) => handleShowLargeImage(e)}
            >
              <Card>
                <CardMedia
                  component="img"
                  height="80"
                  className="img-component"
                  image={impresionsAndPhotosResponse?.step3?.center_bite_url
                    ? impresionsAndPhotosResponse.step3.center_bite_url
                    : ""}
                />
              </Card>
            </div>
            <p className="img-title">Center Bite</p>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginInlineStart: 20,
              alignItems: "center",
            }}
          >
            <div
              style={{ marginBottom: 10 }}
              id="step3b"
              onClick={(e) => handleShowLargeImage(e)}
            >
              <Card>
                <CardMedia
                  component="img"
                  height="80"
                  className="img-component"
                  image={impresionsAndPhotosResponse?.step3?.left_bite_url
                    ? impresionsAndPhotosResponse.step3.left_bite_url
                    : ""}
                />
              </Card>
            </div>
            <p className="img-title">Left Bite</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginInlineStart: 20,
              alignItems: "center",
            }}
          >
            <div
              style={{ marginBottom: 10 }}
              id="step3c"
              onClick={(e) => handleShowLargeImage(e)}
            >
              <Card>
                <CardMedia
                  component="img"
                  height="80"
                  className="img-component"
                  image={impresionsAndPhotosResponse?.step3?.right_bite_url
                    ? impresionsAndPhotosResponse.step3.right_bite_url
                    : ""}
                />
              </Card>
            </div>
            <p className="img-title">Right Bite</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginInlineStart: 20,
              alignItems: "center",
            }}
          >
            <div
              style={{ marginBottom: 10 }}
              id="step3d"
              onClick={(e) => handleShowLargeImage(e)}
            >
              <Card>
                <CardMedia
                  component="img"
                  height="80"
                  className="img-component"
                  image={impresionsAndPhotosResponse?.step3?.upper_teeth_url
                    ? impresionsAndPhotosResponse.step3.upper_teeth_url
                    : ""}
                />
              </Card>
            </div>
            <p className="img-title">Upper Teeth</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginInlineStart: 20,
              alignItems: "center",
            }}
          >
            <div
              style={{ marginBottom: 10 }}
              id="step3e"
              onClick={(e) => handleShowLargeImage(e)}
            >
              <Card>
                <CardMedia
                  component="img"
                  height="80"
                  className="img-component"
                  image={impresionsAndPhotosResponse?.step3?.lower_teeth_url
                    ? impresionsAndPhotosResponse.step3.lower_teeth_url
                    : ""}
                />
              </Card>
            </div>
            <p className="img-title">Lower Teeth</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginInlineStart: 20,
              alignItems: "center",
            }}
          >
            <div
              style={{ marginBottom: 10 }}
              id="step3f"
              onClick={(e) => handleShowLargeImage(e)}
            >
              <Card>
                <CardMedia
                  component="img"
                  height="80"
                  className="img-component"
                  image={impresionsAndPhotosResponse?.step3?.front_facing_no_smile_url
                    ? impresionsAndPhotosResponse.step3.front_facing_no_smile_url
                    : ""}
                />
              </Card>
            </div>
            <div>
              <p className="img-title">Front Facing</p>
              <p className="img-title">No Smile</p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginInlineStart: 20,
              alignItems: "center",
            }}
          >
            <div
              style={{ marginBottom: 10 }}
              id="step3g"
              onClick={(e) => handleShowLargeImage(e)}
            >
              <Card>
                <CardMedia
                  component="img"
                  height="80"
                  className="img-component"
                  image={impresionsAndPhotosResponse?.step3?.front_facing_big_smile_url
                    ? impresionsAndPhotosResponse.step3.front_facing_big_smile_url
                    : ""}
                />
              </Card>
            </div>
            <div>
              <p className="img-title">Front Facing</p>
              <p className="img-title">Big Smile</p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginInlineStart: 20,
              alignItems: "center",
            }}
          >
            <div
              style={{ marginBottom: 10 }}
              id="step3h"
              onClick={(e) => handleShowLargeImage(e)}
            >
              <Card>
                <CardMedia
                  component="img"
                  height="80"
                  className="img-component"
                  image={impresionsAndPhotosResponse?.step3?.right_side_profile_no_smile_url
                    ? impresionsAndPhotosResponse.step3.right_side_profile_no_smile_url
                    : ""}
                />
              </Card>
            </div>
            <div>
              <p className="img-title">Right Side Profile</p>
              <p className="img-title">No Smile</p>
            </div>
          </div>
          {impresionsAndPhotosResponse ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginInlineStart: 20,
                alignItems: "center",
              }}
            >
              <div
                id="step4a"
                onClick={(e) => handleShowLargeImage(e)}
                style={{ marginBottom: 10 }}
              >
                <Card>
                  <CardMedia
                    component="img"
                    height="80"
                    className="img-component"
                    image={impresionsAndPhotosResponse?.step4?.x_ray_url
                      ? impresionsAndPhotosResponse.step4.x_ray_url
                      : ""}
                  />
                </Card>
              </div>
              <p className="img-title">X-Ray</p>
            </div>
          ) : null}
        </div>


      </Card>
      {isOpen && (
        <Lightbox
          startIndex={photoIndex}
          onClose={() => setIsOpen(false)}
          images={imagesArray.map((val) => val.image !== null && val.image)}
        ></Lightbox>
      )}
    </div>
  );
}
