import React, { useState, useEffect } from "react";
import {
  Card,
} from "@mui/material";
import customAxios from "../../axios";
import { useParams } from "react-router-dom";
import "./Screening.css";
import Cookies from "js-cookie";

export default function Screening({ data = null }) {
  let { id } = useParams();
  const [responseScreening, setResponseScreening] = useState(data);
  const [listOfProcedures, setListOfProcedures] = useState([]);

  useEffect(() => {
    if (!data) {
      customAxios
        .get(`users/${id}/your-photos/teleconsult-screening`, {
          headers: {
            Authorization: "Bearer " + Cookies.get("access_token"),
          },
        })
        .then((result) => {
          setResponseScreening(result.data.data);
          result.data.data.list_of_procedures == null
            ? setListOfProcedures([])
            : setListOfProcedures(result.data.data.list_of_procedures);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  return (
    <div className="screening-container">
      <h1 className="screening-title">SCREENING</h1>
      <Card>
        {responseScreening && (
          <Card>
            <div className="screening-wrapper">
              {responseScreening.date_of_birth && (
                <div>
                  <span className="screening-sub-title">Date of Birth: </span>
                  <span>{responseScreening.date_of_birth}</span>
                </div>
              )}
              {responseScreening.problem_question && (
                <div>
                  <span className="screening-sub-title">Chief Complaint: </span>
                  <span>{responseScreening.problem_question}</span>
                </div>
              )}
              {responseScreening.dental_history && (
                <div>
                  <span className="screening-sub-title">Clinical Findings: </span>
                  <span>{responseScreening.dental_history}</span>
                </div>
              )}
              {responseScreening.dental_history_na !== null && (
                <div>
                  <span className="screening-sub-title">
                    {responseScreening.dental_history_na === 1
                      ? "Patient cannot proceed with aligner treatment without prior pre-treatment procedures."
                      : "Patient can proceed with aligner treatment without prior pre-treatment procedures."}
                  </span>
                </div>
              )}
              {responseScreening.dental_history_na === 1 && (
                <div>
                  <span className="screening-sub-title">List of Procedures: </span>
                  {listOfProcedures.map((procedure, index) => (
                    <div key={index}>
                      <span>--</span>
                      <span>{procedure}</span>
                    </div>
                  ))}
                </div>
              )}
              {responseScreening.pain_symptoms && (
                <div>
                  <span className="screening-sub-title">Pain/Symptoms: </span>
                  <span>{responseScreening.pain_symptoms}</span>
                </div>
              )}
            </div>
          </Card>
        )}
      </Card>
    </div>
  );
}