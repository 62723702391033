import React from "react";
import ImageListItem from "@mui/material/ImageListItem";
import IprSheetThumbnail from "../img/ipr-sheet-thumbnail.png";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ImageList from "@mui/material/ImageList";
import { Paper } from "@mui/material";

// const useStyles = styled(() => ({
//   customImageListItemBar: {
//     "& > div": {
//       padding: "8px !important",
//       "& div": {
//         fontSize: "14px",
//         // backgroundColor: 'rgba(0, 128, 96, 1)',
//         textAlign: "center",
//       },
//     },
//   },
// }));

const ImageListThumbnails = ({ pdfUrls, handleClickOpen }) => {
  return (
    <ImageList
      sx={{
        display: "flex",
        flexWrap: "nowrap",
        gap: "0 !important",
        columnGap: "8px !important",
        overflowX: "auto",
        marginY: 4,
      }}
    >
      {pdfUrls.map((procedure, index) => (
        <ImageListItem
          key={index}
          sx={{
            flexShrink: 0,
            cursor: "pointer",
            borderRadius: "5px",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          }}
          onClick={() => handleClickOpen(index)}
        >
          <Paper
            sx={{
              width: 150,
              height: 150,
              overflow: "hidden",
            }}
          >
            <img
              src={IprSheetThumbnail}
              alt={`Image ${index}`}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Paper>
          <ImageListItemBar
            title={procedure.step}
            className="customImageListItemBar"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default ImageListThumbnails;
