import axios from "axios";
import history from "./history";

const customAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
const errorHandler = (response) => {
  console.log(response.status);
  if (response.status === 401) {
    history.push("/");
    return Promise.reject(401);
  } else if (response.status == 422) {
    return Promise.reject(response.status);
  } else {
    return Promise.reject(response.data.errors);
  }
};

const successHandler = (response) => {
  return response;
};

customAxios.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error.response)
);

export default customAxios;
