import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import { withRouter } from "../CustomHooks/withRouter";
import Banner from "../util/Banner/Banner";
import UserHeader from "../Header/UserHeader";
import Cookies from "js-cookie";
import customAxios from "../axios";
import { toast } from "react-toastify";
import emptyIcon from "../img/emptyList.svg";
import "./index.css";
import PDFSheetPreview from "../util/pdf-sheet-preview.component";
import Screening from "../Teleconsultation/Screening/Screening";
import Photos from "../Teleconsultation/Photos/Photos";

function Plan({ router }) {
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const [expanded, setExpanded] = useState("");
  const [filteredProcedures, setFilteredProcedures] = useState([]);
  const [showIPRSheets, setShowIPRSheets] = useState(false);
  const [tp, setTp] = useState(null);
  let { id } = router.params;
  const showIPRSheetsPreview = () => {
    setShowIPRSheets((prev) => !prev);
  };
  const filterProceduresForIprSheet = (procedures) => {
    let modifiedProceduresArray = procedures.map((procedure) => {
      let modifiedObject = {
        step: `${procedure.step} ${procedure.step_position ?? ""}`,
      };
      if (procedure.ipr_sheet && "pdf_url" in procedure.ipr_sheet) {
        modifiedObject["url"] = procedure?.ipr_sheet?.pdf_url;
      }
      return modifiedObject;
    });
    setFilteredProcedures(modifiedProceduresArray);
  };
  const openFile = (e) => {
    window.open(e.currentTarget.id, "_blank");
  };
  const handleChange = (e) => {
    setExpanded((prev) => {
      if (prev === e) {
        return "";
      }
      return e;
    });
  };
  const getTp = async () => {
    try {
      let res = await customAxios.get(`plans/${id}`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
      });
      if (!res.data.success) {
      } else {
        let obj = { ...res.data.plan };
        obj["user"] = res.data.client;
        obj["user"]["shipping"] = res.data.shipping;
        setTp(obj);
        filterProceduresForIprSheet(res.data.plan.procedures);
      }
    } catch (e) {
      console.log(e);
      toast.error("an Error has occured");
    }
    setLoadingPage(false);
  };
  const updatePlans = async (action) => {
    setLoading(true);
    try {
      let res = await customAxios.get(`plans/${tp.id}/${action}`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
      });
      if (!res.data.success) {
        toast.error(res.data.reason);
      } else {
        toast.success("Success");
        router.navigate(0);
      }
    } catch (e) {
      toast.error("an Error has occured");
    }
    setLoading(false);
  };
  useEffect(() => {
    getTp();
  }, []);

  if (loadingPage) {
    return (
      <div className="loading-container" style={{ marginTop: "10vh" }}>
        <div className="loading"></div>
      </div>
    );
  }
  if (tp == null) {
    return (
      <div className="error-container" style={{ marginTop: "10vh" }}>
        <div>
          <Box
            component="img"
            sx={{
              display: "inline-block",
              width: "50px",
            }}
            src={emptyIcon}
          />
        </div>
        <Typography variant="h6">No treatment plan found</Typography>
        <br />
      </div>
    );
  }
  return (
    <div style={{ marginTop: "10vh" }}>
      <div className="teleConsultContainer">
        <UserHeader
          name={tp.user.full_name + " - " + tp.user.id}
          router={router}
          loading={loading}
          tp={tp}
          updatePlans={updatePlans}
        />
        <Accordion
          disableGutters
          expanded={expanded == "details-accordion"}
          onChange={() => {
            handleChange("details-accordion");
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="details-accordion-content"
            id="details-accordion-header"
          >
            <Typography
              variant="h6"
              sx={{ color: "#ff2c46", fontWeight: "bold" }}
            >
              Patient Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                width: "100%",
                whiteSpace: "nowrap",
                overflowX: "auto",
                display: "flex",
                gap: "5%",
                flexDirection: "column",
              }}
            >
              {tp.user.shipping != null ? (
                <div>
                  <Typography
                    sx={{ fontSize: 18, fontWeight: "bold" }}
                    gutterBottom
                  >
                    {tp.user.shipping.full_name} - {tp.user.id}
                  </Typography>
                  <Typography sx={{ fontSize: 16 }}>
                    {tp.user.shipping.email}
                  </Typography>
                  <Typography
                    sx={{ fontSize: 18, fontWeight: "bold" }}
                    gutterBottom
                  >
                    Address:
                  </Typography>
                  <Typography sx={{ fontSize: 16 }}>
                    {tp.user.shipping.phone}
                  </Typography>
                  <Typography sx={{ fontSize: 16 }} color="text.secondary">
                    {tp.user.shipping.address}
                  </Typography>
                  <Typography sx={{ fontSize: 16 }} color="text.secondary">
                    {tp.user.shipping.address2}
                  </Typography>
                  <Typography sx={{ fontSize: 16 }} color="text.secondary">
                    {tp.user.shipping.address3}
                  </Typography>
                  <Typography sx={{ fontSize: 16 }} color="text.secondary">
                    {tp.user.shipping.apartment}
                  </Typography>
                  <Typography sx={{ fontSize: 16 }} color="text.secondary">
                    {tp.user.shipping.postal_code}
                  </Typography>
                  <Typography sx={{ fontSize: 16 }} color="text.secondary">
                    {tp.user.shipping.note}
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5, fontSize: 16 }}
                    color="text.secondary"
                  >
                    {tp.user.shipping.city}, {tp.user.shipping.country}
                  </Typography>
                </div>
              ) : null}
              <Screening />
              <Photos />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          expanded={expanded == "treatment-accordion"}
          onChange={() => {
            handleChange("treatment-accordion");
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="treatment-accordion-content"
            id="treatment-accordion-header"
          >
            <Typography
              variant="h6"
              sx={{ color: "#ff2c46", fontWeight: "bold" }}
            >
              Treatment Plan
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                width: "100%",
                whiteSpace: "nowrap",
                overflowX: "auto",
                display: "flex",
                gap: "5px",
                paddingBottom: "10px",
                flexDirection: "column",
              }}
            >
              <div style={{ margin: "auto" }}>
                {tp.stl_files_link && true ? (
                  <button
                    onClick={(e) => openFile(e)}
                    id={tp.stl_files_link}
                    className="ctaButton"
                  >
                    STL Files
                  </button>
                ) : null}
              </div>
              <div style={{ margin: "auto" }}>
                {tp.guide_url && true ? (
                  <button
                    onClick={(e) => openFile(e)}
                    id={tp.guide_url}
                    className="ctaButton2"
                  >
                    View Treatment Plan
                  </button>
                ) : null}
                {filteredProcedures.length > 0 && (
                  <button onClick={showIPRSheetsPreview} className="ctaButton2">
                    View IPR Sheets
                  </button>
                )}
              </div>
              {showIPRSheets && (
                <div style={{ margin: "auto" }}>
                  <PDFSheetPreview procedures={filteredProcedures} />
                </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default Banner(withRouter(Plan));
