import React, { useState } from "react";
import {
  Button,
  Card,
  CardMedia,
} from "@mui/material";
import "./Scans.css";
import stl from "../../img/stl-thumbnail.png";
import Cookies from "js-cookie";
import customAxios from "../../axios";

export default function Scans({ data = null }) {
  const [loading, setLoading] = useState(false);

  const downloadScans = () => {
    setLoading(true);
    customAxios.get(`freelance/users/${data?.user_id}/download-scans/${data?.id}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("access_token"),
      },
    }).then((result) => {
      var win = window.open(
        `${process.env.REACT_APP_BASE_URL_V2}/storage${result.data.data}`,
        "_blank"
      );
      win.focus();
    }).catch((err) => {
      console.log('err', err);
    }).finally(f => {
      setLoading(false);
    });
  }

  return (
    <div className="scans-container">
      <h1 className="photosTitle">Scans</h1>
      <Card>
        <div className="scans-download-btn">
          <Button disabled={loading} onClick={downloadScans}>Download Scans</Button>
        </div>
        <div className="scans-wrapper" >
          <div className="scans-row-wrapper">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                id="step3a"
                style={{
                  marginBottom: 10,
                }}
              >
                <Card>
                  <CardMedia
                    component="img"
                    height="80"
                    className="img-component"
                    image={data?.document_id || data?.substitute_document_id ? stl : ""}
                  />
                </Card>
              </div>
              <p className="img-title">Upper scan</p>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{ marginBottom: 10 }}
                id="step3b"
              >
                <Card>
                  <CardMedia
                    component="img"
                    height="80"
                    className="img-component"
                    image={data?.lower_scan_id || data?.substitute_lower_scan_id ? stl : ""}
                  />
                </Card>
              </div>
              <p className="img-title">Lower scan</p>
            </div>
          </div>
          <div className="scans-row-wrapper">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{ marginBottom: 10 }}
                id="step3c"
              >
                <Card>
                  <CardMedia
                    component="img"
                    height="80"
                    className="img-component"
                    image={data?.bite_scan_1_id || data?.substitute_bite_scan_1_id ? stl : ""}
                  />
                </Card>
              </div>
              <p className="img-title">Bite scan 1</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{ marginBottom: 10 }}
                id="step3d"
              >
                <Card>
                  <CardMedia
                    component="img"
                    height="80"
                    className="img-component"
                    image={data?.bite_scan_2_id || data?.substitute_bite_scan_2_id ? stl : ""}
                  />
                </Card>
              </div>
              <p className="img-title">Bite scan 2</p>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
