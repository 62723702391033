import React from "react";
import "./UserHeader.css";
import { Button } from "@mui/material";
import { BsArrowLeft } from "react-icons/bs";

const UserHeader = ({
  router,
  name,
  updatePlans = () => { },
  tp = {},
  loading = true,
  isFreelanceCase = false,
}) => {
  return (
    <div className="navHeaderWrap">
      <div
        style={{
          height: "fit-content",
          padding: "10px",
          paddingLeft: "20px",
          fontWeight: "bold",
          fontSize: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Button onClick={() => router.navigate(-1)}>
            <BsArrowLeft color="black" size="2em" />
          </Button>
          <div style={{ display: "flex" }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => window.scroll({ top: 0, behavior: "smooth" })}
            >
              {name}
            </div>
            <div
              style={{ display: "flex", flexWrap: "wrap", columnGap: "5px" }}
            ></div>
          </div>
        </div>
        {
          !isFreelanceCase &&
          <div
            style={{
              display: "flex",
              gap: "5px",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => {
                updatePlans("start");
              }}
              sx={{ color: "#ff2c46" }}
              disabled={tp.oem_production_start_time || loading}
            >
              Started
            </Button>
            <Button
              onClick={() => {
                updatePlans("end");
              }}
              sx={{ color: "#ff2c46" }}
              disabled={
                !tp.oem_production_start_time ||
                tp.oem_production_end_time ||
                loading
              }
            >
              Completed
            </Button>
          </div>
        }
      </div>
    </div>
  );
};

export default UserHeader;
