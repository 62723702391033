import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import logo from "../img/basmalogo.svg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Image } from "@mui/icons-material";
import { useRef, useState } from "react";
import customAxios from "../axios";
import Cookies from "js-cookie";
import { withRouter } from "../CustomHooks/withRouter";

// import { makeStyles } from "@material-ui/core";

// export const useStyles = makeStyles((theme) => ({

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"© Copyright "}
      <Link color="inherit" href="https://basma.com/">
        Basma
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

function SignIn({ router }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const handleSubmit = async (event) => {
    if (errorMessage) {
      setErrorMessage('');
    }
    event.preventDefault();
    try {
      let res = await customAxios.post("auth/login", { email, password });
      Cookies.set("access_token", res.data.access_token);
      router.navigate("plans");
    } catch (e) {
      console.log(e);
      if (e == 422) {
        setErrorMessage("The email or password is incorrect");
      }
    }
    // const data = new FormData(event.currentTarget);
    // let email = data.get('email')
    // let password = data.get('password')
    // document.getElementById('email').value = '';
    // document.getElementById('password').value = '';

    // setEmail('')
    // setPassword('')
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logo} />
          <Typography
            component="h1"
            variant="h5"
            fontWeight="bold"
            sx={{ mt: 2 }}
          >
            Login
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
        <Copyright sx={{ mt: 1 }} />
      </Container>
    </ThemeProvider>
  );
}
export default withRouter(SignIn);
